export default class Popup {

    // open modal automatically after some a certain time
    openModalAutomatically = (_dom, _modal, _backdrop) => {
        setTimeout(() => {
            _modal.classList.add('d-block');

            _modal.removeAttribute('aria-hidden');
            _modal.setAttribute('aria-modal', 'true');
            _modal.setAttribute('role', 'dialog');

            _backdrop.classList.add('fade');

            setTimeout(() => {
                _modal.classList.add('show');
                _backdrop.classList.add('show');
                _dom.classList.add("modal-open");
            }, 100);

        }, 3000);

    }

    // close modal
    closeModal = (_dom, _modal, _backdrop) => {
        _modal.classList.remove('show');

        setTimeout(() => {
            _modal.classList.remove('d-block');
            _modal.removeAttribute('aria-modal');
            _modal.removeAttribute('role');
            _modal.setAttribute('aria-hidden', true);

            _backdrop.classList.remove('show');
            _backdrop.classList.add('hidden');
            _dom.classList.remove("modal-open");

            localStorage.setItem('popup', 'disabled');
        }, 100);
    }

    init() {

        const _dom = document.querySelector("body");
        // const _modalBtns = document.querySelectorAll('.js-open-modal');
        const _modal = document.querySelector('.modal');
        const _backdrop = document.querySelector(".modal-backdrop");

        // iterate through modals and trigger them
        if (_modal && _backdrop) {
            _backdrop.classList.add('fade');

            // open modal automatically
            if (_modal.classList.contains('js-auto') && !localStorage.getItem('popup')) {
                this.openModalAutomatically(_dom, _modal, _backdrop);
            }

            // close Modal on X or Close btn
            const _modalsClose = _modal.querySelector('.js-close');
            if (_modalsClose) {
                _modalsClose.addEventListener('click', () => {
                    this.closeModal(_dom, _modal, _backdrop);
                });
            }
        }

    }
}
