import { tns } from "tiny-slider"

export default class ImageSlider {

	getCarouselOptions (item) {
		return {
			autoplay: true,
			autoWidth: true,
			axis: "horizontal",
			container: item,
			controls: true,
			controlsContainer: '#imageControls',
			prevButton: '.image-previous',
			nextButton: '.image-next',
			items: 1,
			slideBy: 1,
			nav: true,
			loop: true,
			speed: 500,
			gutter: 40,
			mouseDrag: true,
			autoplayButton: false,
			autoplayButtonOutput: false,
			touch: true,
			center: true,
			responsive: {
				576: {
					items: 2
				},
				992: {
					items: 3,
				}
			}
		}
	}

	triggerCarousel() {
		const blocks = document.querySelectorAll(".image-slider");
		if (blocks.length) {
			[...blocks].forEach((block) => {
					const slider = block.querySelector(".image-wrapper");
					let options = this.getCarouselOptions(slider);
					let carousel = tns(options);
				}
			);
		}
	}

	init () {
		this.triggerCarousel()
	}
}
