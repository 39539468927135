import { tns } from "tiny-slider"

export default class CardSlider {

	getCarouselOptions (item) {
		return {
			autoplay: true,
			autoWidth: false,
			axis: "horizontal",
			container: item,
			controls: true,
			items: 1,
			slideBy: 1,
			nav: false,
			loop: true,
			speed: 500,
			gutter: 25,
			mouseDrag: true,
			autoplayButton: false,
			autoplayButtonOutput: false,
			touch: true,
			controlsContainer: '#cardControls',
			prevButton: '.card-previous',
			nextButton: '.card-next',
			responsive: {
				576: {
					items: 2
				},
				992: {
					items: 3
				}
			}
		}
	}

	triggerCarousel() {
		const blocks = document.querySelectorAll(".cards-slider");
		if (blocks.length) {
			[...blocks].forEach((block) => {
					const slider = block.querySelector(".cards-wrapper");
					let options = this.getCarouselOptions(slider);
					let carousel = tns(options);
				}
			);
		}
	}

	init () {
		this.triggerCarousel()
	}
}
