export default class Common {
    elementExists(el) {
        let exists = el !== null && el !== "undefined"

        return Promise.resolve(exists)
    }

    handleScroll() {
        let pageBody = document.body;
        let scrollPosition = pageBody.scrollTop;
        window.addEventListener(
            'scroll',
            checkIfScrolled,
        );

        function checkIfScrolled() {
            (pageBody !== null && pageBody !== 'undefined') ? window.scrollY >= 20 ? pageBody.classList.add('scrolled') : pageBody.classList.remove('scrolled') : null;
        }

        checkIfScrolled();

    }

    scrollToContentOfHashKeyword() {
        const bookNow = document.querySelectorAll('a[href="#bookNow"]');
        const bookNowEl = document.querySelector('#bookNow');

        if (bookNow && bookNowEl) {
            bookNow.forEach((cta) => {
                cta.addEventListener('click', (e) => {

                    e.preventDefault();
                    e.stopPropagation();

                    scrollToEl(bookNowEl);

                });
            });
        }

        function scrollToEl(el) {
            const y = el.getBoundingClientRect().top + window.scrollY - 100;

            window.scroll({
                top: y,
                behavior: 'smooth'
            });
        }

    }


    init() {
        this.handleScroll()
        this.scrollToContentOfHashKeyword()
    }
}
