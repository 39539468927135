import Common from "./Global/Common"
import Menu from "./Global/Menu"
import Popup from "./Global/Popup"
import Ready from "./Helpers/Ready.js"
import LogoSlider from "./Blocks/LogoSlider";
import CardSlider from "./Blocks/CardSlider";
import ImageSlider from "./Blocks/ImageSlider";

Ready(
	() => {
		const common = new Common()
		common.init()

		const menu = new Menu()
		menu.init()

	}
)
