export default class Menu {

    handleMenuMobile() {
        const menuToggler = document.querySelector(".navbar-toggler");
        const menuHolder = document.querySelector(".navbar-collapse");
        const navbarCollapse = document.querySelector("#navbarCollapse");

        function toggleMenu(e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            menuToggler.classList.toggle("open");
            navbarCollapse.classList.toggle("opened");

            if (menuHolder !== null && menuHolder !== "undefined") {
                menuHolder.classList.toggle("collapse");
            }

        }

        if (menuToggler !== null && menuToggler !== "undefined") {
            menuToggler.addEventListener("click", e => {
                toggleMenu(e);
            });

        }
    }

    init() {
        this.handleMenuMobile()
    }
}
